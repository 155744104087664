import React, { useEffect, useState } from "react";
import Div from "@jumbo/shared/Div";
import {
  Autocomplete,
  Box,
  Card,
  CardContent,
  Checkbox,
  TextField,
  Typography,
  FormControlLabel,
  Grid,
} from "@mui/material";
import Link from "@mui/material/Link";
import { ASSET_IMAGES } from "../../../utils/constants/paths";
import { getAssetPath } from "../../../utils/appHelpers";
import { countries } from "../../components/mui/AutoCompletes/data";
import {
  postRequest,
  registerUser,
} from "../../../../backendServices/ApiCalls";
import SweetAlert from "app/pages/components/mui/Alerts/SweetAlert";
import { Link as MyLink, useNavigate, useParams } from "react-router-dom";
import { Form, Formik } from "formik";
import * as yup from "yup";
import JumboTextField from "@jumbo/components/JumboFormik/JumboTextField";
import { LoadingButton } from "@mui/lab";
import { data } from "app/shared/metrics/SalesStatistics/data";
import "../login/login.css";

const validationSchema = yup.object({
  firstname: yup.string("Enter first name").required("first name is required"),
  username: yup
    .string("Enter username")
    .matches(
      /^[a-zA-Z0-9]*$/,
      "Username must only contain alphabets and numbers"
    )
    .matches(/^\S*$/, "Username must not contain spaces")
    .min(6, "Username must be at least 6 characters long")
    .required("Username is required"),
  lastname: yup.string("Enter last name").required("last name is required"),
  email: yup
    .string("Enter email address")
    .email("Invalid email address")
    .required("Email is required"),
  mobile: yup
    .string("Enter mobile number")
    .required("mobile number is required"),
  country: yup.object().required("country is required"),
  mobilecountry: yup.mixed().required("select code"),
  password: yup
    .string()
    .required("Password is required")
    .min(8, "Password must be at least 8 characters long")
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&()+^])[A-Za-z\d@$!%*?&()+^]+$/,
      "Password must include at least one uppercase letter, one lowercase letter, one number, and one special character allowed charecters are @$!%*?&()+^"
    ),
  confirmpassword: yup
    .string()
    .oneOf([yup.ref("password"), null], "Passwords must match")
    .required("Confirm password is required"),
});

const Signup = () => {
  const { referralid, referralside } = useParams();
  const [country, setCountry] = useState(null);
  const [countrieslList, setCountriesList] = useState([]);
  const [stateslList, setStatesList] = useState([]);
  const [citieslList, setCitiesList] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState(
    countries.find(
      (country) => country.label.toLowerCase() === "Nigeria".toLowerCase()
    )
  );
  const [alertData, setalertData] = React.useState({
    show: false,
    message: "",
    variant: "",
  });
  const navigate = useNavigate();
  // const referralSide = referralside === 'L1232738' ? 'L' : referralside === 'R12231333' ? 'R' : ''
  const initialstates = {
    sponsorid: referralid || "JKKJ62GJHF",
    username: "",
    firstname: "",
    lastname: "",
    email: "",
    mobile: "",
    mobilecountry: selectedCountry || null,
    country: null,
    password: "",
    confirmpassword: "",
  };
  const handleSubmit = (data, setSubmitting) => {
    let params = {
      // referralSide,
      sponsorid: data.sponsorid,
      username: data.username,
      firstname: data.firstname,
      lastname: data.lastname,
      email: data.email,
      mobile: `+${selectedCountry.phone}${data.mobile}`,
      password: data.password,
      country: data.country.id,
    };

    registerUser(
      params,
      (response) => {
        if (response?.data?.status === "error") {
          setalertData({
            show: true,
            message: response?.data?.message,
            variant: "error",
          });
          setSubmitting(false);
        } else if (response?.data?.status === "success") {
          setalertData({
            show: true,
            message: response?.data?.message,
            variant: "success",
          });
          setSubmitting(false);
          navigate("/login");
        } else {
          setalertData({
            show: true,
            message: "Something went wrong please try again later",
            variant: "error",
          });
          setSubmitting(false);
        }
      },
      (error) => {
        console.log(error?.response?.data);
      }
    );
  };

  const fetchCountriesList = () => {
    postRequest("/getcountries", "", (response) => {
      setCountriesList(response?.data?.data);
    });
  };

  useEffect(() => {
    fetchCountriesList();
  }, []);

  return (
    <Div
      sx={{
        width: 1000,
        maxWidth: "100%",
        margin: "auto",
        p: 2,
        "@media screen and (max-width: 500px)": {
          paddingBottom: "200px", // Adjust this for your responsive design
        },
      }}
    >
      {alertData.show && (
        <SweetAlert alertData={alertData} setalertData={setalertData} />
      )}
      <center>
        <Card
          sx={{
            display: "flex",
            minWidth: 0,
            flexDirection: { xs: "column", md: "row" },
          }}
        >
          <CardContent
            sx={{
              flex: "0 1 300px",
              position: "relative",
              background: `#0267a0 url(${getAssetPath(
                `${ASSET_IMAGES}/widgets/signup-page.jpg`,
                "640x428"
              )}) no-repeat center`,
              backgroundSize: "cover",

              "&::after": {
                display: "inline-block",
                position: "absolute",
                content: `''`,
                inset: 0,
                backgroundColor: "rgb(27 32 62 / 85%)",
              },
            }}
          >
            <Div
              sx={{
                display: "flex",
                minWidth: 0,
                flex: 1,
                flexDirection: "column",
                color: "common.white",
                position: "relative",
                zIndex: 1,
                height: "100%",
              }}
            >
              <Div sx={{ mb: 2 }}>
                <Typography
                  variant={"h3"}
                  color={"inherit"}
                  fontWeight={500}
                  mb={3}
                >
                  {"Signup"}
                </Typography>

                <Typography variant="p">
                  Already have an account?
                  <MyLink
                    style={{ color: "yellow" }}
                    component="Link"
                    to="/login"
                  >
                    Login
                  </MyLink>
                </Typography>
                <br></br>

                <Typography
                  variant="h3"
                  color={"inherit"}
                  mt={6}
                  sx={{ textAlign: "left" }}
                >
                  Sponsored by
                </Typography>

                <Typography
                  variant="h5"
                  sx={{ textAlign: "left" }}
                  color={"yellow"}
                >
                  User : {`${referralid || "JKKJ62GJHF"}`}
                </Typography>

                {/* <Typography
                variant="h5"
                sx={{ textAlign: "left" }}
                color={"yellow"}
              >
                Leg : {referralSide === 'L' ? 'Left' : referralSide === 'R' ? 'Right' : 'Left'}</Typography> */}
              </Div>

              <Div sx={{ mt: "auto", textAlign: "center" }}>
                <Link href="#" underline="none" sx={{ display: "inline-flex" }}>
                  <img
                    src={`${ASSET_IMAGES}/logo.png`}
                    style={{ width: "150px" }}
                    alt="GDSG"
                  />
                </Link>
              </Div>
            </Div>
          </CardContent>

          <CardContent
            sx={{
              flex: 1,
              pl: 1,
              pr: 1,
              pt: 4,
              pb: 4,
            }}
          >
            <Formik
              validateOnChange={false}
              initialValues={initialstates}
              validationSchema={validationSchema}
              onSubmit={(data, { setSubmitting }) => {
                setSubmitting(true);
                handleSubmit(data, setSubmitting);
              }}
            >
              {({ isSubmitting, values, setFieldValue, touched, errors }) => (
                <Form
                  style={{ textAlign: "left" }}
                  noValidate
                  autoComplete="off"
                >
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={6}>
                      <JumboTextField
                        fullWidth
                        name="username"
                        label={"Username"}
                        type="text"
                      />
                    </Grid>

                    <Grid item xs={12} sm={6}>
                      <JumboTextField
                        fullWidth
                        name="email"
                        label={"Email"}
                        type="email"
                      />
                    </Grid>

                    <Grid item xs={12} sm={6}>
                      <JumboTextField
                        fullWidth
                        label={"First Name"}
                        name="firstname"
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <JumboTextField
                        fullWidth
                        label={"Last Name"}
                        name="lastname"
                      />
                    </Grid>

                    <Grid item xs={3} className="mobilecountry">
                      <Autocomplete
                        sx={{ width: "100%", pr: 0 }}
                        component={TextField}
                        value={selectedCountry}
                        onChange={(event, newValue) => {
                          setSelectedCountry(newValue);
                          setFieldValue("mobilecountry", newValue);
                        }}
                        onInputChange={(event, newValue) => {
                          // Clear the selected country when the user types
                          if (newValue === null) {
                            setSelectedCountry(null);
                          }
                        }}
                        id="mobilecountry-select-demo"
                        options={countries}
                        filterOptions={(options, state) => {
                          const inputValue = state.inputValue.toLowerCase();
                          return options.filter((option) =>
                            option.label.toLowerCase().startsWith(inputValue)
                          );
                        }}
                        autoHighlight
                        getOptionLabel={(option) => `+${option?.phone}`}
                        renderOption={(props, option) => (
                          <Box
                            component="li"
                            sx={{ "& > img": { mr: 1, flexShrink: 0, ml: 0 } }}
                            {...props}
                            style={{ padding: "5px" }}
                          >
                            <img
                              loading="lazy"
                              width="20"
                              src={`https://flagcdn.com/w20/${option.code.toLowerCase()}.png`}
                              srcSet={`https://flagcdn.com/w40/${option.code.toLowerCase()}.png 2x`}
                              alt=""
                            />
                            +{option.phone}
                          </Box>
                        )}
                        renderInput={(params) => (
                          <JumboTextField
                            style={{ paddingRight: 0 }}
                            name="mobilecountry"
                            fullWidth
                            {...params}
                            InputProps={{
                              ...params.InputProps,
                              startAdornment: selectedCountry ? (
                                <img
                                  loading="lazy"
                                  width="20"
                                  src={`https://flagcdn.com/w20/${selectedCountry.code.toLowerCase()}.png`}
                                  srcSet={`https://flagcdn.com/w40/${selectedCountry.code.toLowerCase()}.png 2x`}
                                  alt=""
                                />
                              ) : null,
                            }}
                          />
                        )}
                      />
                    </Grid>
                    <Grid item xs={9}>
                      <JumboTextField
                        fullWidth
                        label={"Mobile No."}
                        name="mobile"
                        type="tel"
                      />
                    </Grid>

                    <Grid item sm={12}>
                      <Autocomplete
                        sx={{ width: "100%" }}
                        value={values.country}
                        onChange={(event, newValue) => {
                          setFieldValue("country", newValue || null);
                          postRequest(
                            "/getstates",
                            { countryid: newValue?.id },
                            (response) => {
                              if (response?.data?.status === "success") {
                                setStatesList(response?.data?.data);
                              }
                            }
                          );
                        }}
                        id="vehmake-select"
                        options={countrieslList}
                        autoHighlight
                        getOptionLabel={(option) => option.name} // Display the title in the dropdown
                        renderOption={(props, option) => (
                          <Box
                            component="li"
                            sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                            {...props}
                          >
                            <img
                              loading="lazy"
                              width="20"
                              src={`https://flagcdn.com/w20/${option.iso2.toLowerCase()}.png`}
                              srcSet={`https://flagcdn.com/w40/${option.iso2.toLowerCase()}.png 2x`}
                              alt=""
                            />
                            {option.name} ({option.iso2}) +{option.phonecode}
                          </Box>
                        )}
                        getOptionSelected={(option, value) =>
                          option.id === value.id
                        } // Match by code
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            name="country"
                            label="Select Country"
                            variant="outlined"
                            error={touched.country && errors.country}
                            helperText={touched.country && errors.country}
                          />
                        )}
                      />
                    </Grid>

                    <Grid item xs={12} sm={6}>
                      <JumboTextField
                        fullWidth
                        label={"Password"}
                        type="password"
                        name="password"
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <JumboTextField
                        fullWidth
                        label={"Confirm Password"}
                        name="confirmpassword"
                        type="password"
                      />
                    </Grid>

                    <Grid item xs={12} sm={12}>
                      <FormControlLabel
                        control={<Checkbox defaultChecked />}
                        label={
                          "I hereby confirm that I agreed to terms and conditions."
                        }
                        // label=" I hereby confirm that I am aged of over 16 and agree to terms and conditions."
                      />
                    </Grid>
                    <Grid item xs={12} sm={12}>
                      <LoadingButton
                        fullWidth
                        type="submit"
                        variant="contained"
                        size="large"
                        sx={{ mb: 3 }}
                        loading={isSubmitting}
                      >
                        Register Now
                      </LoadingButton>
                    </Grid>
                  </Grid>
                </Form>
              )}
            </Formik>
          </CardContent>
        </Card>
      </center>
    </Div>
  );
};

export default Signup;
