import { Container, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Grid, Paper, Typography, TextField, Divider, Button, CircularProgress, Autocomplete } from '@mui/material'
import { getproduct, postRequest } from 'backendServices/ApiCalls';
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import {  Form, Formik } from "formik";
import * as yup from "yup";
import { useContext } from 'react';
import { LoadingButton } from '@mui/lab';
import JumboTextField from '@jumbo/components/JumboFormik/JumboTextField';
import { CustomProvider } from 'app/layouts/vertical-default/VerticalDefault';
import Div from '@jumbo/shared/Div/Div';
import SweetAlert from '../components/mui/Alerts/SweetAlert';

const validationSchema = yup.object({
  firstname: yup.string().required('First Name is required'),
  lastname: yup.string().required('Last Name is required'),
  email: yup.string().email('Invalid email address').required('Email is required'),
  mobile: yup.string().required('Mobile is required'),
  address: yup.string().required('Address is required'),
  stockist: yup
    .object()
    .required('stockist is required'),
    city: yup
    .object()
    .required("city is required")

});



const Checkout = () => {


  const [imageurl, setImageUrl] = useState(null);

  const nav = useNavigate();
  const {  loginUserData,cartItems, setCartItems } = useContext(CustomProvider);
  const [alertData, setalertData] = React.useState({
    show: false,
    message: "",
    variant: ""
  })

  const [isLoading, setIsLoading] = useState(true);
  const [shippingCharges, setShippingCharges] = useState('--');
  const [totalAmount, setTotalAmount] = useState('--');
  const [btnDisabled, setBtnDisabled] = useState(true);
  const [stockistList, setStockistList] = React.useState([]);
const [citiesList, setCitiesList] = React.useState([]);
const [selectedCity, setSelectedCity] = React.useState(null);


  const GetAllProducts = () => {

    getproduct((response) => {
      setImageUrl(response?.data?.imageURL)
      if (response?.data === "success") { // change in everyone
        console.log("response get Successfully");
      }
    }, (error) => {
      console.log(error?.response?.data);
    })
  }
  //const subtotal = cartItems.reduce((total, item) => total + item.price * item.quantity, 0);

  const { subtotal, totalWeight, totalPvs } = cartItems.reduce(
    (accumulators, item) => {
      // Calculate the total price
      accumulators.subtotal += item.price * item.quantity;
      // Calculate the total product weight
      accumulators.totalWeight += item.weight * item.quantity;
      accumulators.totalPvs += item.pvs * item.quantity;
  
      return accumulators;
    },
    { subtotal: 0, totalWeight: 0 }
  );

  const handleSubmit = (data, setSubmitting, resetForm) => {

    let addressdata1=JSON.stringify(data)
    const userData = JSON.parse(addressdata1);
    userData.totalOrderAmount = subtotal

    let cartData = JSON.parse(localStorage.getItem('cart'));
    const orderData  = cartData.map((product, index) => ({
      product_id: product.id, // Assuming 'id' represents the product ID
      product_title: product.title, // Assuming 'id' represents the product ID
      amount: product.price,
      quantity:  product.quantity,
      weight:  product.weight,
      pvs:  product.pvs,
    }));
   const formData = {userData , orderData}
   
    postRequest('/submitorder', formData, (response) => {

      if (response?.data?.status === "success") {
        setalertData({
          show: true,
          message: 'your order details has been submitted successfully',
          variant: "success"
        })
        localStorage.setItem('cart', null);
        localStorage.setItem('orderMailingDetails', null);
        localStorage.setItem('totalAmountToPay', null)
        setCartItems([]);
        nav(`/success/${response?.data?.orderid}/${response?.data?.activeStatus}`)
      } else if (response?.data?.status === "error") {

        setalertData({
          show: true,
          message: response?.data?.message,
          variant: response?.data?.status
        })
      } else {

        setalertData({
          show: true,
          message: response?.data?.message,
          variant: response?.data?.status
        })
      }

    }, (error) => {
      console.log(error?.response?.data);

    })

    }

;
  

  
  useEffect(() => {
    const storedCart = JSON.parse(localStorage.getItem('cart')) || [];
    setCartItems(storedCart);
    GetAllProducts();

  }, []);


  const initialValues = {
    firstname: loginUserData.firstname || '',
    lastname: loginUserData.lastname || '',
    email: loginUserData.email || '',
    mobile: loginUserData.mobile || '',
    address: loginUserData.address ||  '',
    city:selectedCity,
    stockist:null
  };


  const fetchCitiesList = () => {
    if(loginUserData.state !== undefined)
    {
  
      postRequest('/getcities',{stateid:loginUserData.state}, async (response) => {
        await setCitiesList(response?.data?.data);
        const city = await response?.data?.data.find((city) => {
          return city.id == loginUserData.city;
        });
        await setSelectedCity(city);
      });
    }
  };
  
  const getStockistList = (cityid) => {
    // Handle the successful payment
    postRequest('/getstockistlist',{cityid},(response) => {
        setStockistList(response?.data?.data)
        setIsLoading(false)
        }, (error) => {
           console.error(error); 
  
        })
  };
  useEffect(async () => {
    await fetchCitiesList()
  }, [])
  
  useEffect(async () => {
    await getStockistList(loginUserData?.city)
  }, [selectedCity])
  

  if(isLoading)
  {
      return  <Div
      sx={{
          display: 'flex',
          minWidth: 0,
          alignItems: 'center',
          alignContent: 'center',
          height: '100%',
      }}
    >
      <CircularProgress sx={{m: '-40px auto 0'}}/>
    </Div>
  }
  
  return (
    <>
      <Container>
      {
            alertData.show && (<SweetAlert alertData={alertData} setalertData={setalertData} />)
          }
        <Typography p={2} variant='h3' color='#000' textAlign='center' mb={2} bgcolor='#a785ed'> CheckOut </Typography>
        <Formik
        enableReinitialize={true}
                            validateOnChange={true}
                            initialValues={initialValues}
                            validationSchema={validationSchema}
                            onSubmit={(data, { setSubmitting, resetForm }) => {
                                setSubmitting(true);
                                handleSubmit(data, setSubmitting, resetForm);
                            }}
                        >
                            {({ isSubmitting,values,setFieldValue,touched,errors }) => (
                                <Form style={{ textAlign: 'left' }} noValidate autoComplete='off'>
<Grid container spacing={2} >
                <Grid item xs={6} md={7}>
                  <Grid container spacing={2}>
                    <Grid item sm={6} xs={12}>
                      <JumboTextField
                        fullWidth
                        name="firstname"
                        label="First Name"
                        type="text"
                      />

                    </Grid>
                    <Grid item sm={6} xs={12} >
                      <JumboTextField
                        fullWidth
                        label="Last Name"
                        name='lastname'
                        type="text"
                      />

                    </Grid>

                    <Grid item sm={6} sx={{ width: { xs: '100%' } }} >
                      <JumboTextField
                        fullWidth
                        label="Email"
                        name='email'
                        type='email'
                      />

                    </Grid>


                    <Grid item sm={6} sx={{ width: { xs: '100%' } }} >
                      <JumboTextField
                        fullWidth
                        label="Mobile"
                        name='mobile'
                        type='tel'
                      />

                    </Grid>

                    <Grid item sm={6}>
                        <Autocomplete
                      sx={{ width: "100%" }}
                      value={values.city}
                      onChange={async (event, newValue) => {
                        await setFieldValue('city',newValue);
                        await getStockistList(newValue?.id)
                      }}
                      id="vehmake-select"
                      options={citiesList}
                      autoHighlight
                      getOptionLabel={(option) => option.name} // Display the title in the dropdown
                      getOptionSelected={(option, value) =>
                        option.id === value.id
                      } // Match by code
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          name="city"
                          label="Select city"
                          variant="outlined"
                          error={touched.city && errors.city}
                          helperText={touched.city && errors.city}
                        />
                      )}
                    />
                        </Grid>

                <Grid item xs={6}>
                <Autocomplete
                      sx={{ width: "100%" }}
                      value={values.stockist}
                      onChange={(event, newValue) => {
                        setFieldValue('stockist',newValue);

                      }}
                      id="vehmake-select"
                      options={stockistList.length > 0 ? stockistList : [{ storename: 'No stockist found', address: 'No' }]}                      autoHighlight
                      getOptionLabel={(option) => `${option?.storename} (${option?.address})`} // Display the title in the dropdown
                      getOptionSelected={(option, value) =>
                        option.stockistid === value.stockistid
                      } // Match by code
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          name="stockist"
                          label="Select nearest stockist"
                          variant="outlined"
                          error={touched.stockist && errors.stockist}
                          helperText={touched.stockist && errors.stockist}
                        />
                      )}
                    />
                </Grid>

                  </Grid>

                </Grid>
                <Grid item xs={6} md={5} p={1}>
                  <TableContainer component={Paper}>
                    <Table sx={{ minWidth: 150 }}>
                      <TableHead style={{ fontWeight: 'bolder' }}>
                        <TableRow >
                          <TableCell></TableCell>
                          <TableCell>Product</TableCell>
                          <TableCell>Price</TableCell>
                          <TableCell>Quantity</TableCell>
                        </TableRow>
                      </TableHead>

                      <TableBody>
                        {cartItems.map((item) => (
                          <>
                            <TableRow key={item.id}>
                              <TableCell>
                                <img src={`${imageurl}${item.picture}`} alt={item.title} height="40"
                                  style={{ borderRadius: "50%", width: '40px' }} />

                              </TableCell>
                              <TableCell > {item.title}</TableCell>
                              <TableCell>N{item.price}</TableCell>
                              <TableCell>{item.quantity}</TableCell>
                            </TableRow>

                          </>
                        ))}


                      </TableBody>
                    </Table>
                  </TableContainer>
                  <Div sx={{ mt: 1,  pr: 2 }}>

                  <TableContainer>
      <Table aria-label="simple table">

        <TableBody>
        <TableRow
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell component="th" scope="row">
                Sub Total
              </TableCell>
              <TableCell align="right">N{subtotal}</TableCell>
            </TableRow>
            <TableRow
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              {/* <TableCell component="th" scope="row">
                Shipping Charges
              </TableCell>
              <TableCell align="right">N{shippingCharges}</TableCell> */}
            </TableRow>

            <TableRow
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell component="th" scope="row">
                Total Amount
              </TableCell>
              <TableCell align="right">N{subtotal}</TableCell>
            </TableRow>

            <TableRow
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell component="th" scope="row">
                Account Balance
              </TableCell>
              <TableCell align="right">N{loginUserData?.accountbalance}</TableCell>
            </TableRow>

        </TableBody>
      </Table>
    </TableContainer>
                  </Div>

                  <Div sx={{ mt: 1, pl: 2, pr: 2 }}>
                                        <LoadingButton
                                            fullWidth
                                            type="submit"
                                            variant="contained"
                                            size="large"
                                            sx={{ mb: 3 }}
                                            // loading={isSubmitting}
                                        >Submit Order</LoadingButton>
                  </Div>
                </Grid>
              </Grid>
            </Form>
          )}
        </Formik>
      </Container>

    </>
  )
}

export default Checkout
