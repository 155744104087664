import React, { useEffect, useState } from "react";
import {
  Grid,
  CircularProgress,
  TextField,
  IconButton,
  Alert,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
import MarketingCampaign from "app/shared/widgets/MarketingCampaign/MarketingCampaign";
import ObjectCountRevenue from "../../../shared/metrics/ObjectCountCards/ObjectCountRevenue";
import AccountBalanceWalletRoundedIcon from "@mui/icons-material/AccountBalanceWalletRounded";
import RedeemIcon from "@mui/icons-material/Redeem";
import GroupAddIcon from "@mui/icons-material/GroupAdd";
import Referralusers from "app/shared/widgets/ReferralUsers/ReferralUsers";
import { AlertTitle, Stack } from "@mui/material";
import PaymentsIcon from '@mui/icons-material/Payments';
import {
  referralusers,
  lasttransactions,
  dashBoardApi,
  postRequest,
} from "backendServices/ApiCalls";
import { useContext } from "react";
import { CustomProvider } from "app/layouts/vertical-default/VerticalDefault";

import Div from "@jumbo/shared/Div/Div";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import {
  ContentCopyOutlined,
  ShoppingCartCheckoutRounded,
  StackedLineChart,
} from "@mui/icons-material";
// import { ShoppingBagOutlined, ShoppingBasket, ShoppingCartCheckoutRounded } from "@mui/icons-material";
import useSwalWrapper from "@jumbo/vendors/sweetalert2/hooks";
import JumboCardQuick from "@jumbo/components/JumboCardQuick";
import WarningAmberIcon from "@mui/icons-material/WarningAmber";
import EarningExpenses from "app/shared/metrics/EarningExpenses";
import PortfolioBalance from "app/shared/metrics/PortfolioBalance";
import NewConnections from "../../../shared/widgets/NewConnections";
import RecentActivities from "../../../shared/widgets/RecentActivities";
import UpgradePlan from "../../../shared/widgets/UpgradePlan";
import BitcoinPrice from "app/pages/dashboard/Crypto/components/BitcoinPrice";
import RipplePrice from "app/pages/dashboard/Crypto/components/RipplePrice";
import EthereumPrice from "app/pages/dashboard/Crypto/components/EthereumPrice";
import LitecoinPrice from "app/pages/dashboard/Crypto/components/LitecoinPrice";

const Dashboard = () => {

  const [stockistData, setStockistData] = useState([])
  const [loader, setLoader] = useState(false);
  const { loginUserData, loading, setalertData } = useContext(CustomProvider);
  let userData = loginUserData;
  

  const getStockistData = () => {
    return new Promise((resolve, reject) => {
      // Assuming dashBoardApi internally uses callbacks
      postRequest('/getStockistData','',
        (response) => resolve(response),
        (error) => reject(error)
      );
    });
  };

  const fetchData = () => {
    setLoader(true);

    // Make all API calls concurrently using Promise.all
    Promise.all([getStockistData()])
      .then(
        ([stockistData]) => {
          setStockistData(stockistData?.data?.data);
          setLoader(false);
        }
      )
      .catch((error) => {
        setLoader(false);
        console.error(error?.response?.data);
      });
  };

  useEffect(() => {
    fetchData();
  }, []);

  if (loading || loader) {
    return (
      <Div
        sx={{
          display: "flex",
          minWidth: 0,
          alignItems: "center",
          alignContent: "center",
          height: "100%",
        }}
      >
        <CircularProgress sx={{ m: "-40px auto 0" }} />
      </Div>
    );
  }


  return (
    <Grid container spacing={2}>

            <Grid item xs={12} sm={6} lg={3}>
                <BitcoinPrice value={userData?.completedlevelusers*5} title='Wallet Balance' color="primary.main" icon={<AccountBalanceWalletRoundedIcon fontSize='large'/>} vertical={true}/>
            </Grid>
            <Grid item xs={12} sm={6} lg={3}>
                <RipplePrice value={userData?.totalStockistBonus}  title='All Time Stockist Bonus' color="info.main"  icon={<StackedLineChart fontSize='large'/>}  vertical={true}/>
            </Grid> 
            <Grid item xs={12} sm={6} lg={3}>
                <EthereumPrice value={stockistData.pendingPackageOrders} title='Pending Package Orders' color="secondary.main"  icon={<AccountBalanceWalletRoundedIcon fontSize='large'/>}  vertical={true}/>
            </Grid>
            <Grid item xs={12} sm={6} lg={3}>
                <LitecoinPrice value={stockistData?.pendingProductOrders} title='Pending Product Orders' color="success.main"  icon={<RedeemIcon fontSize='large'/>}  vertical={true}/>
            </Grid>
    </Grid>
  );
};

export default Dashboard;