import React, { useContext } from 'react';
import JumboDemoCard from "@jumbo/components/JumboDemoCard";
import Box from "@mui/material/Box";
import {Button, Grid, Typography } from "@mui/material";
import { CustomProvider } from 'app/layouts/vertical-default/VerticalDefault';
import SweetAlert from 'app/pages/components/mui/Alerts/SweetAlert';
import Div from '@jumbo/shared/Div/Div';
import { postRequest } from 'backendServices/ApiCalls';
import { useNavigate } from 'react-router-dom';
import PayPalButton from '../components/mui/Payments/Paypal';



const Investment = () => {

  const [alertData, setalertData] = React.useState({
    show: false,
    message: "",
    variant: ""
  })
  const {setCartItems } = useContext(CustomProvider);
  const subtotal = localStorage.getItem('totalAmountToPay')

  const nav = useNavigate()




  const handlePaymentSuccess = (transactionDetails) => {
    // Handle the successful payment
    let addressdata1=localStorage.getItem('orderMailingDetails') 

    const userData = JSON.parse(addressdata1);

    let cartData = JSON.parse(localStorage.getItem('cart'));
    const orderData  = cartData.map((product, index) => ({
      product_id: product.id, // Assuming 'id' represents the product ID
      product_title: product.title, // Assuming 'id' represents the product ID
      amount: product.price,
      quantity:  product.quantity,
      weight:  product.weight,
    }));
   const formData = {userData , orderData}
    postRequest('/submitorder', formData, (response) => {
      
      if (response?.data?.status === "success") {
        setalertData({
          show: true,
          message: 'your order details has been submitted successfully',
          variant: "success"
        })
        localStorage.setItem('cart', null);
        localStorage.setItem('orderMailingDetails', null);
        localStorage.setItem('totalAmountToPay', null)
        setCartItems([]);
        nav(`/success/${response?.data?.orderid}/${response?.data?.activeStatus}`)
      } else if (response?.data?.status === "error") {
        setalertData({
          show: true,
          message: response?.data?.message,
          variant: response?.data?.status
        })
      } else {
        setalertData({
          show: true,
          message: 'server error',
          variant: "success"
        })
      }

    }, (error) => {
      console.log(error?.response?.data);

    })
  };
  return (
    <Grid container spacing={2} alignItems="center" justifyContent="center" >
      {
        alertData.show && (<SweetAlert alertData={alertData} setalertData={setalertData} />)
      }
      <Grid item sm={8} >
        <JumboDemoCard title={"Payment Methods"}
          wrapperSx={{ backgroundColor: 'background.paper', pt: 0 }}>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
            }}
            alignItems="center"
          >
            <>
              <Div sx={{ mt: 2, }}>
                <Typography variant="h3" gutterBottom component="div">
                  Please Select Your Payment Method Below
                </Typography>
              </Div>
              <PayPalButton sx={{ width: '100%' }} amount={subtotal} onSuccess={handlePaymentSuccess} />
              {/* <Button color='primary' variant='contained' sx={{ mt: 1, width: '80%' }} onClick={handlePaymentSuccess}>
                Pay Using Wise
              </Button> */}
            
            </>
          </Box>

        </JumboDemoCard>
      </Grid>
    </Grid>
  );
};

export default Investment;








