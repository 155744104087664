import React from "react";

import Page from "@jumbo/shared/Page";
import ViewReferrals from "app/pages/referrals/viewreferrals";
import Signup from "app/pages/auth-pages/signup/signup";
import UserProfile from "app/pages/users/user-profile/UserProfile";
import ReferralBonusReport from "app/pages/reports/refferal/ReferralBonusReport";
import Logout from "app/pages/auth-pages/logout/Logout";
import Payout from "app/pages/payout/payout";
import PayoutReports from "app/pages/reports/payout/PayoutReports";
import ForgotPassword from "app/pages/auth-pages/forgot-password/ForgotPassword";
import ResetPassword from "app/pages/auth-pages/reset-password/ResetPassword";
import BinaryTree from "app/pages/treeview/BinaryTree";
import Test from "app/pages/Test";
import NonDisclosure from "app/pages/nondisclosure/NonDisclosure";
import Ecommerce from "app/pages/ecommerce/Ecommerce";
import Cart from "app/pages/cart/Cart";
import Checkout from "app/pages/checkout/Checkout";
import Payment from "app/pages/payment/Payment";
import Login from "app/pages/auth-pages/login/Login";
import OrderHistory from "app/pages/Order/OrderHistory";
import UniLevelBonus from "app/pages/reports/unilevel/UnilevelReport";
import Success from "app/pages/success/Success";
import News from "app/pages/news/news"
import Crypto from "app/pages/dashboard/Crypto/Crypto";
import BuyPackage from "app/pages/package/BuyPackage";
import investment from "app/pages/investment";
import PackageHistory from "app/pages/package/PackageHistory";
import NewPackageRequests from "app/pages/stockist/packages/NewPackageRequests";
import ApprovedPackages from "app/pages/stockist/packages/ApprovedPackages";
import Deposit from "app/pages/deposit/Deposit";
import DepositReport from "app/pages/reports/deposit/DepositReport";
import PendingOrder from "app/pages/stockist/orders/pendingOrder/PendingOrder";
import ApprovedOrder from "app/pages/stockist/orders/approvedOrders/ApprovedOrder";
import DeliveredPackages from "app/pages/stockist/packages/DeliveredPackages";
import DeliveredOrder from "app/pages/stockist/orders/deliveredOrders/DeliveredOrder";
import Dashboard from "app/pages/stockist/dashboard/Dashboard";
import StockistBonusReport from "app/pages/stockist/bonusreport/StockistBonus";
import BalanceTransfer from "app/pages/stockist/balancetransfer/BalanceTransfer";
import BinaryBonusReport from "app/pages/reports/binarybonus/BinaryBonusReport";
import PoolBonusReport from "app/pages/reports/poolbonus/PoolBonusReport";
import WalletTransfer from "app/pages/reports/wallettransfer/WalletTransfer";
import BinaryPoints from "app/pages/reports/binarypoints/BinaryPoints";
import Rank from "app/pages/reports/ranks/Rank";
import RankBonusReport from "app/pages/reports/RankBonus/RankBonusReport";
import RoiReport from "app/pages/reports/roi/roi";

/**
 routes which you want to make accessible to both authenticated and anonymous users
 **/
const routesForPublic = [



];

/**
 routes only accessible to authenticated users
 **/
const routesForAuthenticatedOnly = [
    {
        path: "/",
        element: <Page component={Crypto} />
    },
    {
        path: "/dashboard",
        element: <Page component={Crypto} />
    },
    {
        path: "/deposit",
        element: <Page component={Deposit} />
    },
    {
        path: "/deposit-report",
        element: <Page component={DepositReport} />
    },
    {
        path: "/ecommerce",
        element: <Page component={Ecommerce} />
    },
    {
        path: "/cart",
        element: <Page component={Cart} />
    },
    {
        path: "/success/:orderid/:activestatus",
        element: <Page component={Success} />
    },  
    {
        path: "/checkout",
        element: <Page component={Checkout} />
    },
    {
        path: "/payment",
        element: <Page component={Payment} />
    },
    {
        path: "/buy-now",
        element: <Page component={investment} />
    },
    {
        path: "/referrals",
        element: <Page component={ViewReferrals} />
    },    
    {
        path: "/profile",
        element: <Page component={UserProfile} />
    },
    {
        path: "/unilevel-summary",
        element: <Page component={UniLevelBonus} />
    },
    {
        path: "/binary-points",
        element: <Page component={BinaryPoints} />
    },
    {
        path: "/referral-bonus",
        element: <Page component={ReferralBonusReport} />
    },
    {
        path: "/rank-summary",
        element: <Page component={Rank} />
    },
    {
        path: "/rank-bonus-report",
        element: <Page component={RankBonusReport} />
    },
    {
        path: "/binary-bonus",
        element: <Page component={BinaryBonusReport} />
    },
    {
        path: "/pool-bonus",
        element: <Page component={PoolBonusReport} />
    },
    {
        path: "/wallet-transfer-report",
        element: <Page component={WalletTransfer} />
    },
    {
        path: "/orderhistory",
        element: <Page component={OrderHistory} />
    },
    {
        path: "/packages",
        element: <Page component={BuyPackage} />
    },
    {
        path: "/payout-summary",
        element: <Page component={PayoutReports} />
    },
    {
        path: "/roi-summary",
        element: <Page component={RoiReport} />
    },
    {
        path: "/package-history",
        element: <Page component={PackageHistory} />
    },
    {
        path: "/stockist-packages-requests",
        element: <Page component={NewPackageRequests} />
    },
    {
        path: "/stockist-packages-approved",
        element: <Page component={ApprovedPackages} />
    },
    {
        path: "/stockist-packages-delivered",
        element: <Page component={DeliveredPackages} />
    },
    {
        path: "/stockist-new-product-orders",
        element: <Page component={PendingOrder} />
    },
    {
        path: "/stockist-products-approved-order",
        element: <Page component={ApprovedOrder} />
    },
    {
        path: "/stockist-products-delivered-order",
        element: <Page component={DeliveredOrder} />
    },
    {
        path: "/stockist-dashboard",
        element: <Page component={Dashboard} />
    },
    {
        path: "/stockist-bonus",
        element: <Page component={StockistBonusReport} />
    },
    {
        path: "/wallet-transfer",
        element: <Page component={BalanceTransfer} />
    },
    {
        path: "/binary-tree",
        element: <Page component={BinaryTree} />
    },
    {
        path: "/binary-tree/:randomcode",
        element: <Page component={BinaryTree} />
    },
    {
        path: "/payout",
        element: <Page component={Payout} />
    },
    {
        path: "/news",
        element: <Page component={News} />
    },
    {
        path: "/non-disclosure",
        element: <Page component={NonDisclosure}  />
    },
    {
        path: "/logout",
        element: <Page component={Logout} />
    },
];

/**
 routes only accessible when user is anonymous
 **/
 const routesForNotAuthenticatedOnly = [
    {
        path: "/login",
        element: <Page component={Login}  />
    },
    {
        path: "/login/:token/:email",
        element: <Page component={Login}  />
    },
    {
        path: "/test",
        element: <Page component={Test}  />
    },
    {
        path: "/signup/:referralid",
        element: <Page component={Signup}  />
    },
    {
        path: "/signup/:referralid/:referralside",
        element: <Page component={Signup}  />
    },
    {
        path: "/signup",
        element: <Page component={Signup}  />
    },
    {
        path: "/forget-password",
        element: <Page component={ForgotPassword}  />
    },

    {
        path: "/reset-password/:token/:email",
        element: <Page component={ResetPassword}  />
    },
 ];


const routes = [
    ...routesForPublic,
    ...routesForAuthenticatedOnly,
    ...routesForNotAuthenticatedOnly,
];

export {routes as default, routesForPublic, routesForNotAuthenticatedOnly, routesForAuthenticatedOnly};