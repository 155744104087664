import React, { useEffect, useState, useContext } from "react";
import PackageBox from "./components/PackageBox";
import { Alert, CircularProgress, Grid } from "@mui/material";
import { postRequest } from "backendServices/ApiCalls";
import Div from "@jumbo/shared/Div";
import { CustomProvider } from "app/layouts/vertical-default/VerticalDefault";

const BuyPackage = () => {
  const [packages, setpackages] = useState([]);
  const [loading, setloading] = useState(true);
  const { loginUserData } = useContext(CustomProvider);

  const getPackagesList = () => {
    postRequest(
      "/getpackageslist",
      "",
      (response) => {
        if (response?.data?.status === "success") {
          setpackages(response?.data?.data);
          setloading(false);
        }
      },
      (error) => {
        console.log(error?.response?.data);
      }
    );
  };

  useEffect(() => {
    getPackagesList();
  }, []);

  if (loading) {
    return (
      <Div
        sx={{
          display: "flex",
          minWidth: 0,
          alignItems: "center",
          alignContent: "center",
          height: "100%",
        }}
      >
        <CircularProgress sx={{ m: "-40px auto 0" }} />
      </Div>
    );
  }

  return (
    <Grid container spacing={2}>
      {
        loginUserData?.status === 'in process'
          ?
          <Grid item sm={12} lg={12} md={12}>
            <Alert sx={{ color: "white" }} variant="filled">Your request has been submitted successfully to admin. It will be verify soon. Thank You!</Alert>
          </Grid>
          : loginUserData?.status === 'approved'
            ?
            <Grid item sm={12} lg={12} md={12}>
              <Alert sx={{ color: "white" }} variant="filled">You have already purchased a subscription successfully. Thank You!</Alert>
            </Grid>
            :
            <>
              {packages.map((pdata, index) => (
                <Grid sx={{ marginBottom: 3 }} item sm={4} key={index}>
                  <PackageBox pdata={pdata} />
                </Grid>
              ))}
            </>
      }

    </Grid>
  );
};

export default BuyPackage;
