import React from 'react';
import {Button, Typography} from "@mui/material";
import JumboCardQuick from "@jumbo/components/JumboCardQuick";
import {useTranslation} from "react-i18next";
import {TrendingUp} from "@mui/icons-material";
import ChartLastMonthSales from "./ChartLastMonthSales";
import { useContext } from 'react';
import { CustomProvider } from 'app/layouts/vertical-default/VerticalDefault';
import { Link } from 'react-router-dom';

const BitcoinPrice = (props) => {
    const {t} = useTranslation();
    const {loginUserData} = useContext(CustomProvider);

    return (
        <JumboCardQuick
            noWrapper
            style={{paddingBottom:'50px', textAlign:'center'}}
            title={<Typography variant={"h2"} color={"common.white"}>{(loginUserData.stockist_balance || 0).toLocaleString('en-NG', {style: 'currency',currency: 'NGN',})}</Typography>}
            subheader={
                <Typography
                    variant={"h6"}
                    color={"common.white"}
                    mb={0}
                >{props.title}</Typography>
            }
            // action={
            //     <Link to="/target-route">
            //     <Button color='primary' size='small' variant="contained">
            //       send to main wallet
            //     </Button>
            //      </Link>
            // }
            sx={{color: "common.white"}}
            bgColor={"#6f42c1"}
            headerSx={{pb: 0}}
        >
                <Link to="/wallet-transfer" >
                <Button color='primary' sx={{marginTop:'60px',}} size='small' variant="contained">
                  send to main wallet
                </Button>
                 </Link>
        </JumboCardQuick>
    );
};

export default BitcoinPrice;
