import React from "react";
import { ListItemText, Typography } from "@mui/material";
import TrendingDownIcon from "@mui/icons-material/TrendingDown";
import TrendingUpIcon from "@mui/icons-material/TrendingUp";
import ListItemButton from "@mui/material/ListItemButton";

const CampaignItem = ({ item, key }) => {
  return (
    <ListItemButton
      component={"li"}
      key={key}
      sx={{ p: (theme) => theme.spacing(1, 3) }}
    >
      {/* <ListItemAvatar>
                <Avatar alt={item?.name} sx={{color: 'common.white', bgcolor: `${item?.bgcolor}`}}>
                    {item?.icon}
                </Avatar>
            </ListItemAvatar> */}
      <ListItemText
        sx={{
          flexBasis: "30%",
        }}
        primary={"Type"}
        secondary={<Typography variant="h5" mb={0.5}>
          {item?.type.charAt(0).toUpperCase() + item?.type.slice(1)}
        </Typography>}
      />
      <ListItemText
        primary={"Amount"}
        secondary={
          <Typography variant="body1" sx={{ fontSize: 13 }}>
            {'$' + (item?.amount || 0)}
          </Typography>
        }
      />

      <ListItemText
        primary={"Sender"}
        secondary={
          <Typography variant="body1" sx={{ fontSize: 13 }}>
            {(item?.sender || "")}
          </Typography>
        }
      />

      <ListItemText
        primary={"Receiver"}
        secondary={
          <Typography variant="body1" sx={{ fontSize: 13 }}>
            {(item?.receiver || "")}
          </Typography>
        }
      />

    </ListItemButton>
  );
};
/* Todo item prop define */

export default CampaignItem;
