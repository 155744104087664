import React from 'react'
import Typography from "@mui/material/Typography";
import JumboCardQuick from "@jumbo/components/JumboCardQuick";
import {useTranslation} from "react-i18next";
import {TrendingDown} from "@mui/icons-material";
import ChartNewVisitors from "./ChartNewVisitors";
import { useContext } from 'react';
import { CustomProvider } from 'app/layouts/vertical-default/VerticalDefault';
import { Link } from 'react-router-dom';
import { Button } from '@mui/material';

const EthereumPrice = (props) => {
    const {t} = useTranslation();
    const {loginUserData} = useContext(CustomProvider);

    return (
        <JumboCardQuick
            noWrapper
            title={<Typography variant={"h2"} color={"common.white"}>{props.value || 0}</Typography>}
            subheader={
                <Typography
                    variant={"h6"}
                    color={"common.white"}
                    mb={0}
                >{props.title}</Typography>
            }
            action={
                <Link to="/stockist-packages-requests">
                <Button color='primary' size='small' variant="contained">
                  View Report
                </Button>
                 </Link>
            }
            bgColor={"#E73145"}
            sx={{color: "common.white"}}
            headerSx={{pb: 0}}
        >
            <ChartNewVisitors/>
        </JumboCardQuick>
    );
};

export default EthereumPrice;
