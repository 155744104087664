import React, { useContext } from 'react';
import Typography from "@mui/material/Typography";
import JumboCardQuick from "@jumbo/components/JumboCardQuick";
import {useTranslation} from "react-i18next";
import {TrendingUp} from "@mui/icons-material";
import ChartTotalRevenue from "./ChartTotalRevenue";
import { CustomProvider } from 'app/layouts/vertical-default/VerticalDefault';
import { Link } from 'react-router-dom';
import { Button } from '@mui/material';

const LitecoinPrice = (props) => {
    const {t} = useTranslation();
    const {loginUserData} = useContext(CustomProvider);
    return (
        <JumboCardQuick
            title={<Typography variant={"h3"} color={"common.white"}>{props.value || 0}</Typography>}
            subheader={
                <Typography
                    variant={"h6"}
                    color={"common.white"}
                    mb={0}
                >{props.title}</Typography>
            }
            action={
                <Link to="/stockist-new-product-orders">
                <Button color='secondary' size='small' variant="contained">
                  View Report
                </Button>
                 </Link>
            }
            bgColor={["#23BCBA", "#45E994"]}
            wrapperSx={{pt: 0}}
        >
            <ChartTotalRevenue/>
        </JumboCardQuick>
    );
};

export default LitecoinPrice;
