import React, { useContext } from "react";
import {
  Box,
  Button,
  Card,
  CardContent,
  Fab,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import GradeIcon from '@mui/icons-material/Grade';

import { CustomProvider } from "app/layouts/vertical-default/VerticalDefault";
import { useNavigate } from "react-router-dom";
const PackageBox = ({ pdata }) => {
  const { loginUserData } = useContext(CustomProvider);
  const navigate = useNavigate();

  return (
    <Card sx={{ textAlign: "center" }}>
      <CardContent
        sx={{
          backgroundImage: "linear-gradient(#01DDBD, #2B55D0)",
          backgroundRepeat: "repeat-x",
          py: 6,
        }}
      >
        <Typography
          variant={"h1"}
          fontWeight={"300"}
          sx={{ fontSize: "3rem", color: "common.white", mb: 0 }}
        >
          {pdata?.title}
        </Typography>
      </CardContent>
      <CardContent sx={{ position: "relative" }}>
        <Fab
          aria-label="volume"
          color="primary"
          sx={{
            position: "absolute",
            left: "50%",
            top: "0",
            height: 80,
            width: 80,
            transform: "translate(-50%, -50%)",
            color: "#fff",
          }}
        >
          <GradeIcon style={{ fontSize: '3rem' }} />
        </Fab>
        <TableContainer sx={{ mt: 2, mb: 2 }}>
          <Table sx={{ width: "100%" }} size="small" aria-label="a dense table">
            <TableBody>

              <TableRow>
                <TableCell align="left" component="th">
                  Amount
                </TableCell>
                <TableCell align="right">${pdata?.amount}</TableCell>
              </TableRow>

              <TableRow>
                <TableCell align="left" component="th">
                  Return On Investment
                </TableCell>
                <TableCell align="right">{pdata?.roi}%</TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>

        <Button
          variant={"contained"}
          // disabled={loginUserData?.pkgid >= pdata?.id ? true : false}
          onClick={() => {
            navigate("/buy-now", { state: { data: pdata } });
          }}
          color={"primary"}
          disableElevation
        >
          {/* {loginUserData?.pkgid >= pdata?.id ? "Already a member" : "Buy Now"} */}
          {"Buy Now"}
        </Button>
      </CardContent>
    </Card>
  );
};

export default PackageBox;
