import React, { useEffect, useState } from "react";
import {
  Bar,
  BarChart,
  CartesianGrid,
  Legend,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import JumboDemoCard from "@jumbo/components/JumboDemoCard";
import code from "./demo-code/tiny-bar-chart.txt";
// import { postRequest } from "./yourPostRequestFile"; // Import your postRequest function
import data from "./data";
import { postRequest } from "backendServices/ApiCalls";

const RoiChart = () => {
  const [roiGraph, setRoiGraph] = useState([]);

  const RoiGraphData = () => {
    postRequest(
      "/roigraphdata",
      "",
      (response) => {
        console.log("response", response?.data?.data);
        // const arr = response?.data?.data.map(item => ({ amount: item.amount, createdat: item.createdat }));
        const arr = [];
        response?.data?.data.forEach((item) => {
          const amount = item.amount;
          const createdat = new Date(item.createdat);
          // const day = createdat.getDate();
          const day = createdat.toLocaleDateString('en-US', { weekday: 'long' });

          arr.push({ name: day, pv: amount, amt: amount, });
        });

        console.log("jdhviudvhguhs", arr);
        setRoiGraph(arr);
        if (response?.data?.status === "success") {
          console.log("response get Successfully");
        }
      },
      (error) => {
        console.log(error?.response?.data);
      }
    );
  };

  useEffect(() => {
    RoiGraphData();
  }, []);

  return (
    <JumboDemoCard
      title={"ROI Graph"}
      demoCode={code}
      wrapperSx={{ pt: 0, backgroundColor: "background.paper" }}
    >
      <ResponsiveContainer width="100%" height={200}>
        <BarChart data={roiGraph} margin={{ top: 0, right: 0, left: 0, bottom: 0 }}>
          <XAxis dataKey="name" />
          <YAxis />
          <CartesianGrid strokeDasharray="3 3" />
          <Tooltip
            labelStyle={{ color: "black" }}
            itemStyle={{ color: "black" }}
            cursor={false}
          />
          <Legend />
          <defs>
            <linearGradient x1="0" y1="0" x2="0" y2="1">
              <stop offset="5%" stopColor="#6200EE" stopOpacity={1} />
              <stop offset="95%" stopColor="#B819D2" stopOpacity={1} />
            </linearGradient>
          </defs>
          <defs>
            <linearGradient x1="0" y1="0" x2="0" y2="1">
              <stop offset="5%" stopColor="#1ABBDE" stopOpacity={1} />
              <stop offset="95%" stopColor="#09BCA7" stopOpacity={1} />
            </linearGradient>
          </defs>
          <Bar dataKey="pv" fill={"#1e88e5"} />
          {/* <Bar dataKey="uv" fill={"#e91e63"} /> */}
        </BarChart>
      </ResponsiveContainer>
    </JumboDemoCard>
  );
};

export default RoiChart;
