import React, { useEffect, useState } from "react";
import {
  Grid,
  CircularProgress,
  TextField,
  IconButton,
  Alert,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
import MarketingCampaign from "app/shared/widgets/MarketingCampaign/MarketingCampaign";
import ObjectCountRevenue from "../../../shared/metrics/ObjectCountCards/ObjectCountRevenue";
import AccountBalanceWalletRoundedIcon from "@mui/icons-material/AccountBalanceWalletRounded";
import GroupAddIcon from "@mui/icons-material/GroupAdd";
import Referralusers from "app/shared/widgets/ReferralUsers/ReferralUsers";
import PaymentsIcon from "@mui/icons-material/Payments";
import {
  referralusers,
  lasttransactions,
  postRequest,
} from "backendServices/ApiCalls";
import { useContext } from "react";
import { CustomProvider } from "app/layouts/vertical-default/VerticalDefault";

import Div from "@jumbo/shared/Div/Div";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { ContentCopyOutlined } from "@mui/icons-material";
// import { ShoppingBagOutlined, ShoppingBasket, ShoppingCartCheckoutRounded } from "@mui/icons-material";
import useSwalWrapper from "@jumbo/vendors/sweetalert2/hooks";
import JumboCardQuick from "@jumbo/components/JumboCardQuick";
import WarningAmberIcon from "@mui/icons-material/WarningAmber";
import UpgradePlan from "../../../shared/widgets/UpgradePlan";
import UserSummary from "app/shared/widgets/UserSummary";
import RoiChart from "./components/RoiGraph/RoiChart";

const Crypto = () => {
  const [lasttransactionsdata, setLastTransactionsData] = useState([]);
  const [picturelink, setPictureLink] = useState([]);
  const [referralusersdata, setReferralUsersData] = useState([]);
  const [copied, setCopied] = useState(false);
  const [referralLink, setReferralLink] = useState("");

  const [dashboardData, setDashboardData] = useState([]);
  const [loader, setLoader] = useState(false);
  const { loginUserData, loading, setalertData } = useContext(CustomProvider);
  let userData = loginUserData;
  const promoStatus = loginUserData?.settingsdata?.find(
    (sdata) => sdata.keyname === "promo"
  );
  const promoImage = loginUserData?.settingsdata?.find(
    (sdata) => sdata.keyname === "promo_image"
  );

  const Swal = useSwalWrapper();
  const sweetAlerts = () => {
    Swal.fire({
      imageUrl: `https://onedollarnetwork.threearrowstech.com/uploads/promo/${promoImage.keyvalue}`, // Replace with your image URL
      imageAlt: "Promo image",
      confirmButtonText: "Close",
    });
  };

  const AllDashboardData = () => {
    postRequest(
      "/dashboarddata",
      "",
      (response) => {
        console.log('response', response)
        setDashboardData(response?.data?.data);
        if (response?.data?.status === "success") {
          console.log("response get Successfully");
        }
      },
      (error) => {
        console.log(error?.response?.data);
      }
    );
  };

  const ReferralUsers = () => {
    return new Promise((resolve, reject) => {
      // Assuming dashBoardApi internally uses callbacks
      referralusers(
        (response) => resolve(response),
        (error) => reject(error)
      );
    });
  };

  const TransactionData = () => {
    return new Promise((resolve, reject) => {
      // Assuming dashBoardApi internally uses callbacks
      lasttransactions(
        (response) => resolve(response),
        (error) => reject(error)
      );
    });
  };

  const fetchData = () => {
    setLoader(true);

    // Make all API calls concurrently using Promise.all
    Promise.all([ReferralUsers(), TransactionData()])
      .then(([referralUsersResponse, transactionsResponse]) => {
        setReferralUsersData(referralUsersResponse?.data?.data?.entries);
        setPictureLink(referralUsersResponse?.data?.data?.picturelink);
        setLastTransactionsData(transactionsResponse?.data?.data?.entries);
        setLoader(false);
      })
      .catch((error) => {
        setLoader(false);
        console.error(error?.response?.data);
      });
  };

  useEffect(() => {
    AllDashboardData();
    fetchData();
    if (promoStatus?.keyvalue === "On") {
      sweetAlerts();
    }
  }, []);

  if (loading || loader) {
    return (
      <Div
        sx={{
          display: "flex",
          minWidth: 0,
          alignItems: "center",
          alignContent: "center",
          height: "100%",
        }}
      >
        <CircularProgress sx={{ m: "-40px auto 0" }} />
      </Div>
    );
  }
  const handleCopy = () => {
    setCopied(true);
    // Update referralLink before copying
    setReferralLink(loginUserData?.referrallink);
    navigator.clipboard.writeText(loginUserData?.referrallink);
    setalertData({
      show: true,
      message: "Referral link copied to clipboard",
      variant: "success",
    });
    setTimeout(() => {
      setCopied(false);
    }, 2000);
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} sm={4}>
        <UserSummary userData={userData} picturelink={picturelink} />
      </Grid>

      <Grid item xs={12} sm={4}>
        <ObjectCountRevenue
          value={`$${userData?.current_balance}`}
          title="Current Balance"
          color="primary.main"
          icon={<AccountBalanceWalletRoundedIcon fontSize="large" />}
          vertical={true}
        />
      </Grid>
      <Grid item xs={12} sm={4}>
        <ObjectCountRevenue
          value={`$${dashboardData?.totalRoiBonus}`}
          title="ROI"
          color="info.main"
          icon={<AccountBalanceWalletRoundedIcon fontSize="large" />}
          vertical={true}
        />
      </Grid>
      <Grid item xs={12} sm={4}>
        <ObjectCountRevenue
          value={dashboardData?.totalActiveReferral}
          title="Direct Active Referrals"
          color="info.main"
          icon={<GroupAddIcon fontSize="large" />}
          vertical={true}
        />
      </Grid>
      <Grid item xs={12} sm={4}>
        <ObjectCountRevenue
          value={`$${dashboardData?.totalUnilevelBonus}`}
          title="Unilevel Bonus"
          color="info.main"
          icon={<PaymentsIcon fontSize="large" />}
          vertical={true}
        />
      </Grid>

      <Grid item xs={12} sm={4}>
        <ObjectCountRevenue
          value={`$${dashboardData?.totalPayoutAmount}`}
          title="Total Payout"
          color="info.main"
          icon={<PaymentsIcon fontSize="large" />}
          vertical={true}
        />
      </Grid>


      <Grid item xs={12}>
        <JumboCardQuick
          title={"Referral Link"}
          subheader={""}
          wrapperSx={{ p: 1 }}
          headerSx={{ borderBottom: 1, borderBottomColor: "divider" }}
        >
          {userData?.status === "approved" ? (
            <Grid container spacing={2}>
              <Grid item sm={12} xs={12}>
                <TextField
                  fullWidth

                  type="text"
                  value={loginUserData?.referrallink}
                  margin="normal"
                  InputProps={{
                    readOnly: true,
                    endAdornment: (
                      <IconButton>
                        {copied ? (
                          <CheckCircleIcon color="success" />
                        ) : (
                          <ContentCopyOutlined
                            onClick={handleCopy}
                            color="warning"
                          />
                        )}
                      </IconButton>
                    ),
                  }}
                />
              </Grid>
            </Grid>
          ) : (
            <Alert
              icon={<WarningAmberIcon />}
              variant="outlined"
              style={{ color: "red" }}
            >
              Please buy a membership to activate your referral link.
            </Alert>
          )}
        </JumboCardQuick>
      </Grid>

      {/* <Grid item xs={12} lg={6}>
        <PortfolioBalance
          totalroi={userData?.totalEarningdbData?.total_earning}
          weeklyroi={userData?.totalEarningdbData?.weekly_earning}
          monthlyroi={userData?.totalEarningdbData?.monthly_earning}
        />
      </Grid>
      <Grid item xs={12} lg={6}>
        <EarningExpenses
          earning={userData?.totalEarning}
          spending={userData?.totalSpending}
        />
      </Grid> */}
      <Grid item xs={12} lg={6}>
        <Referralusers
          picturelink={picturelink}
          referralusersdata={referralusersdata}
        />
      </Grid>
      <Grid item xs={12} lg={6}>
        <MarketingCampaign lasttransactionsdata={lasttransactionsdata} />
      </Grid>
      <Grid item xs={12} lg={12}>
        <RoiChart />
      </Grid>
      {/* <Grid item xs={12} sm={8}>
        <NewConnections />
      </Grid>
      <Grid item xs={12} sm={4}>
        <RecentActivities userlevel={dashBoardUse?.levelopen} />
      </Grid> */}
    </Grid>
  );
};

export default Crypto;
