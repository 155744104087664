import React, { useContext, useEffect, useState } from "react";
import JumboDemoCard from "@jumbo/components/JumboDemoCard";
import Box from "@mui/material/Box";
import {
  Alert,
  CircularProgress,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { CustomProvider } from "app/layouts/vertical-default/VerticalDefault";
import SweetAlert from "app/pages/components/mui/Alerts/SweetAlert";
import { postRequest } from "backendServices/ApiCalls";
import { CSSTransition } from "react-transition-group";
import { Form, Formik } from "formik";
import * as yup from "yup";
import "./CollapsibleBox.css"; // Import CSS for transition animation
import JumboTextField from "@jumbo/components/JumboFormik/JumboTextField";
import { LoadingButton } from "@mui/lab";
import { useLocation, useNavigate } from "react-router-dom";
import { ContentCopy } from "@mui/icons-material";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import CameraAltIcon from "@mui/icons-material/CameraAlt";

const validationSchema = yup.object({
  package: yup.string().required("Subscription plan is required"),
  price: yup.string().required("Amount is required"),
  // walletaddress: yup.string().required("Payment method is required"),
});

const convertBase64 = (file) => {
  return new Promise((resolve, reject) => {
    const fileReader = new FileReader();
    fileReader.readAsDataURL(file);
    fileReader.onload = () => {
      resolve(fileReader.result);
    };
    fileReader.onerror = (error) => {
      reject(error);
    };
  });
};

const Investment = () => {
  const [alertData, setalertData] = React.useState({
    show: false,
    message: "",
    variant: "",
  });

  const { loginUserData, setloginUserData } = useContext(CustomProvider);
  const navigate = useNavigate();
  const [adminwalletdata, setAdminWalletData] = useState([]);
  const [selectedOption, setSelectedOption] = useState("");
  const [loading, setLoading] = useState(true);
  const [isCopied, setIsCopied] = useState(false);
  const [image, setImage] = useState(null);
  const { state } = useLocation();
  const pdata = state?.data || {};
  if (state === null) {
    navigate("/packages");
  }

  const WalletData = () => {
    let params = {
      keynames: "'depositwallet'",
    };
    postRequest(
      "/getadminwallet",
      params,
      (response) => {
        setLoading(true);
        setAdminWalletData(response?.data?.data?.entries);
        setLoading(false);
      },
      (error) => {
        console.log(error?.response?.data);
        setLoading(false);
      }
    );
  };

  const handleSelectChange = (event) => {
    setSelectedOption("");
    setLoading(true);
    const coinid = event.target.value;
    let params = {
      tid: coinid,
    };
    postRequest(
      "/getsingledepositwallet",
      params,
      (response) => {
        setSelectedOption(response?.data?.data);
        setLoading(false);
      },
      (error) => {
        console.log(error?.response?.data);
        setLoading(false);
      }
    );

    //setSelectedOption();
    // Call your function here
  };

  useEffect(() => {
    WalletData();
  }, []);

  const handleCopy = () => {
    navigator.clipboard.writeText(
      selectedOption?.entries[0]?.walletaddress || ""
    );
    setIsCopied(true);

    // Reset the state after a certain duration if needed
    setTimeout(() => {
      setIsCopied(false);
    }, 2000);

    // setalertData({
    //   show: true,
    //   message: "address copied to clipboard",
    //   variant: "success",
    // });
  };

  const handleUpload = async (e) => {
    if (e.target.files && e.target.files.length > 0) {
      const selectedFile = e.target.files[0];
      if (selectedFile.type.startsWith("image/")) {
        const base64Image = await convertBase64(selectedFile);
        await setImage(base64Image);
      } else {
        await setalertData({
          show: true,
          message: "Invalid file type. Please select an image file.",
          variant: "error",
        });
      }
    }
  };

  const handleSubmit = (data, setSubmitting, resetForm) => {
    if (selectedOption === "") {
      setalertData({
        show: true,
        message: "Please select payment method.",
        variant: "error",
      });
      setSubmitting(false);
    }
    else {
      let pkgId = pdata?.id;
      let params = {
        packageid: pkgId,
        image: image,
        paymentmethod: selectedOption?.entries[0]?.coinname,
        walletaddress: selectedOption?.entries[0]?.walletaddress,
      };
      postRequest(
        "/submitpackagerequest",
        params,
        async (response) => {
          if (response?.data?.status === "success") {
            setalertData({
              show: true,
              message: "Your request has been submitted successfully to admin. It will be verify soon.",
              variant: "success",
            });
            navigate("/dashboard");
          } else {
            setalertData({
              show: true,
              message: response?.data?.message,
              variant: response?.data?.status,
            });
            setSubmitting(false);
          }
        },
        (error) => {
          setalertData({
            show: true,
            message: error.message,
            variant: "error",
          });
        }
      );
    }
  };

  const initialstates = {
    package: pdata?.title,
    price: `$${pdata?.amount}`,
    walletaddress: "",
  };

  return (
    <Grid container spacing={2} alignItems="center" justifyContent="center">
      {alertData.show && (
        <SweetAlert alertData={alertData} setalertData={setalertData} />
      )}

      <Grid item sm={8}>
        <JumboDemoCard
          title={"Subscription"}
          wrapperSx={{ backgroundColor: "background.paper", pt: 0 }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
            }}
            alignItems="center"
          >
            <CSSTransition
              in={true}
              classNames="collapse"
              timeout={300}
              unmountOnExit
            >
              <Box
                sx={{
                  mt: 2,
                  p: 2,
                  border: "1px solid #ccc",
                  borderRadius: "4px",
                }}
              >
                <Formik
                  validateOnChange={false}
                  enableReinitialize="true"
                  initialValues={initialstates}
                  validationSchema={validationSchema}
                  onSubmit={(data, { setSubmitting, resetForm }) => {
                    setSubmitting(true);
                    handleSubmit(data, setSubmitting, resetForm);
                  }}
                >
                  {({
                    isSubmitting,
                    values,
                    setFieldValue,
                    touched,
                    errors,
                  }) => (
                    <Form
                      style={{ textAlign: "left" }}
                      noValidate
                      autoComplete="off"
                    >
                      <Grid container spacing={2}>
                        <Grid item xs={12}>
                          <JumboTextField
                            fullWidth
                            name="package"
                            label="Package"
                            type="text"
                            inputProps={{ readOnly: true }}
                          />
                        </Grid>

                        <Grid item xs={12}>
                          <JumboTextField
                            fullWidth
                            name="price"
                            label="Package Amount"
                            type="text"
                            inputProps={{ readOnly: true }}
                          />
                        </Grid>

                        <Grid item xs={12}>
                          <InputLabel id="select-label">
                            Select deposit method
                          </InputLabel>
                          <Select
                            labelId="select-label"
                            value={selectedOption.tid}
                            fullWidth
                            onChange={handleSelectChange}
                          >
                            {adminwalletdata.map((option) => (
                              <MenuItem key={option.value} value={option.tid}>
                                {option.coinname}
                              </MenuItem>
                            ))}

                            {/* Add more options if needed */}
                          </Select>
                        </Grid>

                        {loading ? (
                          <Grid
                            xs={12}
                            sx={{
                              display: "flex",
                              minWidth: 0,
                              alignItems: "center",
                              alignContent: "center",
                              height: "100%",
                              marginTop: "50px",
                            }}
                          >
                            <CircularProgress sx={{ m: "-40px auto 0" }} />
                          </Grid>
                        ) : null}
                        {selectedOption ? (
                          <Grid item xs={12}>
                            <Box
                              sx={{
                                display: "flex",
                                flexDirection: "column",
                              }}
                              alignItems="center"
                            >
                              <TextField
                                type="text"
                                name="walletaddress"
                                value={
                                  selectedOption?.entries[0]?.walletaddress
                                }
                                margin="normal"
                                label="Deposit Account"
                                fullWidth
                                InputProps={{
                                  readOnly: true,
                                  endAdornment: (
                                    <InputAdornment position="end">
                                      <IconButton onClick={handleCopy}>
                                        {isCopied ? (
                                          <CheckCircleOutlineIcon />
                                        ) : (
                                          <ContentCopy />
                                        )}
                                      </IconButton>
                                    </InputAdornment>
                                  ),
                                }}
                              />

                              <Alert severity="info">
                                {selectedOption?.entries[0]?.walletmessage}
                              </Alert>

                            </Box>
                          </Grid>
                        ) : null}

                        <Grid item xs={12}>
                          <TextField
                            fullWidth
                            style={{ marginTop: "0" }}
                            onChange={(e) => {
                              setFieldValue(
                                "paymentproof",
                                e.target.files[0]
                              );
                              handleUpload(e);
                            }}
                            name="paymentproof"
                            type="file"
                            label="Payment Proof"
                            margin="normal"
                            InputLabelProps={{
                              shrink: true,
                            }}
                            InputProps={{
                              endAdornment: (
                                <CameraAltIcon
                                  fontSize={"small"}
                                  color={"success"}
                                />
                              ),
                            }}
                            error={Boolean(errors.paymentproof)}
                            helperText={errors.paymentproof}
                          />
                        </Grid>

                        <Grid item sm={12}>
                          <LoadingButton
                            fullWidth
                            type="submit"
                            variant="contained"
                            size="large"
                            sx={{ mb: 3 }}
                          //loading={isSubmitting}
                          >
                            Submit
                          </LoadingButton>
                        </Grid>
                      </Grid>
                    </Form>
                  )}
                </Formik>
              </Box>
            </CSSTransition>
          </Box>
        </JumboDemoCard>
      </Grid>
    </Grid>
  );
};

export default Investment;
